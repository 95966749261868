import * as React from "react"
import VTO from "../components/vto/vto"
import Seo from "../components/seo"
import "../assets/css/style.css"
import "../assets/css/layout.css"
import "../assets/css/vto-style.css"
import { organizationSchema } from "../const/schemas.pl"
import { languages } from "../const/languages"

const ServicesPage = () => <VTO lang={languages.PL} />

export const Head = () => {
  return (
    <>
      <Seo
        title="AR-Labs.io | Wirtualna przymierzalnia na wiele platform"
        description="Zrewolucjonizuj wirtualne doświadczenie zakupowe swoich klientów dzięki rozwiązaniu wirtualnej przymierzalni od AR-Labs.io na smartfonach i tabletach z systemem Android oraz iOS, zintegruj je ze swoją stroną internetową lub użyj go z cyfrowymi wyświetlaczami."
        canonicalURL="https://staging-area.ar-labs.io/pl/vto"
      />
      <script type="application/ld+json">{organizationSchema}</script>
    </>
  )
}

export default ServicesPage
